<template>
  <div class="fieldMap">
    <div class="nav-bar-wrap">
      <van-nav-bar
        title="全部地块分布"
        left-text="返回"
        left-arrow
        @click-left="onNavClick"
      />
    </div>
    <div id="map"></div>
  </div>
</template>
<script>
import { NavBar } from "vant";
import AMap from "AMap";
import { getFieldList } from "@/service/serverPage/myField.js";
export default {
  name: "FieldMap",
  components: {
    "van-nav-bar": NavBar
  },
  data() {
    return {
      map: null,
      icon: require("../../assets/image/marker/maker1.png"),
      fieldList: []
    };
  },
  methods: {
    onNavClick() {
      this.$router.go(-1);
    },
    init() {
      this.map = new AMap.Map("map", {
        zoom: 5,
        resizeEnable: true,
        center: [116.397428, 39.90923] // 中心点坐标
      });
    },
    addMarker(markerInfo) {
      const tempLnglat = new AMap.LngLat(markerInfo.lng, markerInfo.lat);
      const marker = new AMap.Marker({
        position: tempLnglat
      });
      marker.setLabel({
        direction: "top",
        content: `
          <div class="marker-info">
            ${markerInfo.name}
          </div>
        `
      });
      this.map.add(marker);
    }
  },
  created() {
    getFieldList().then(res => {
      if (res.code === 200) {
        res.data.forEach(item => {
          if (item.longitude && item.latitude) {
            this.addMarker({
              lng: item.longitude,
              lat: item.latitude,
              name: item.name
            });
          }
        });
      }
    });
  },
  mounted() {
    this.init();
    // 测试，当前农田暂无经纬度； 如果农田有经纬度可以删除下行代码
    // this.addMaker({ lat: 39, lng: 110, name: "我是一个很撑很长的地名啊" });
  }
};
</script>
<style>
.nav-bar-wrap {
  position: fixed;
  width: 100vw;
  z-index: 999;
  top: 0;
}
#map {
  width: 100vw;
  height: 100vh;
}
/* 高德地图弹出框样式 */
.fieldMap .amap-marker-label {
  border: none;
}
.marker-info {
  font-size: 14px;
  padding: 6px;
  border-radius: 4px;
  background: #0000b0;
  color: #fff;
}
</style>
